import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  PageLoader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { FilterSearch } from "@eventsquare/uikit/filters";
import {
  RiCheckDoubleLine,
  RiCheckLine,
  RiMailSendLine,
} from "@remixicon/react";
import { useCallback, useEffect, useState } from "react";

import { Api } from "@lib/api";
import { formatDate, stopPropagation } from "@lib/helpers";

interface Invitation {
  created_at: string;
  email: string;
  expires_at: string | null;
  failed_at: string | null;
  id: string;
  message: string | null;
  opened_at: string | null;
  processed_at: string | null;
  reference: string;
  reserved_at: string | null;
  status:
    | "created"
    | "failed"
    | "processed"
    | "expired"
    | "accepted"
    | "opened";
  type: "free" | "paylink" | "pos";
  user: {
    email: string;
    firstname: string | null;
    id: string;
    lastname: string | null;
  };
}

const INVITATIONS_ON_PAGE = 25;

export const EditionInvitations = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { eventId, editionId } = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [invitationsTotal, setInvitationsTotal] = useState<number | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState("");

  const fetchInvitations = useCallback(async () => {
    try {
      setLoading(true);
      const activePage = searchParams.get("page")
        ? parseInt(searchParams.get("page")!)
        : 1;
      const search = searchParams.get("search") ?? "";
      const params: {
        limit: number;
        offset: number;
        search?: string;
        // payment?: string[];
        // hide_failed?: boolean;
      } = {
        limit: INVITATIONS_ON_PAGE,
        offset: (activePage - 1) * INVITATIONS_ON_PAGE,
      };

      if (search) {
        params["search"] = search;
      }
      const { invitations, meta } = await Api.get(
        `/editions/${editionId}/invitations`,
        params
      );
      setInvitations(invitations);
      setInvitationsTotal(meta.pagination.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId, searchParams]);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue === "") {
        searchParams.delete("search");
        setSearchParams({ ...searchParams, page: "1" });
        return;
      }
      setSearchParams({ ...searchParams, search: searchValue, page: "1" });
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  const getInvitationStatus = (invitation: Invitation) => {
    switch (invitation.status) {
      case "processed":
        return <RiCheckLine color={"var(--theme-font-color-disabled)"} />;
      case "accepted":
        return <RiCheckDoubleLine color={"var(--theme-color-success)"} />;
      case "opened":
        return <RiCheckLine color={"var(--theme-color-success)"} />;
      case "failed":
      case "created":
      case "expired":
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Invitations">
          <ButtonGroup noMargin>
            <FilterSearch value={searchValue} setValue={setSearchValue} />
            <Button onClick={() => console.log("Export")} variant="outline">
              Export
            </Button>
            {/* <FilterToggle filterActive={} /> */}
          </ButtonGroup>
        </PageHeader>

        <Container>
          {loading && <PageLoader />}
          {!loading && invitations && invitations.length === 0 && (
            <Empty
              title={"No invitations found"}
              lead={"No invitations found"}
              icon={<RiMailSendLine />}
            />
          )}
          {!loading && invitations && invitations.length > 0 && (
            <>
              <Table scrollable clickable>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Reference</TableHeadCell>
                    <TableHeadCell>Source</TableHeadCell>
                    <TableHeadCell textAlign="center">Status</TableHeadCell>
                    <TableHeadCell>Created</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invitations.map((invitation) => {
                    const createdAt = formatDate(
                      invitation.created_at,
                      user!.language,
                      true
                    );
                    const invitationUrl = `/events/${eventId}/editions/${editionId}/invitations/${invitation.id}`;
                    return (
                      <TableRow
                        key={invitation.id}
                        onClick={() => navigate(invitationUrl)}
                      >
                        <TableCell>
                          <Text variant="h6" noMargin>
                            <Link to={invitationUrl} onClick={stopPropagation}>
                              {invitation.reference}
                            </Link>
                          </Text>
                        </TableCell>
                        <TableCell>{invitation.type}</TableCell>
                        <TableCell textAlign="center">
                          {getInvitationStatus(invitation)}
                        </TableCell>
                        <TableCell>
                          {createdAt.charAt(0).toUpperCase() +
                            createdAt.slice(1)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                activePage={
                  searchParams.get("page")
                    ? parseInt(searchParams.get("page")!)
                    : 1
                }
                itemsTotal={invitationsTotal ?? 0}
                itemsPerPage={INVITATIONS_ON_PAGE}
                setActivePage={(page: number) => {
                  setSearchParams({ ...searchParams, page: page.toString() });
                }}
                translations={{
                  first_page: "First",
                  last_page: "Last",
                  active_page: "page {{activePage}} from {{totalPages}}",
                }}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
