import { Language } from "@type/languages";
import { Show } from "@type/shows";

export const getNowInAccountTimezone = (timezone: string) => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone }));
  // Timezone offest in minutes
  const offset = (tzDate.getTime() - utcDate.getTime()) / 6e4;
  // Apply offset
  return date.getTime() - offset * 6e4;
};

export const formatDate = (
  date: string,
  language: "nl" | "en" | "fr",
  fullDateTime: boolean = false
) => {
  const options: {
    weekday?: "long" | "short" | "narrow";
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
  } = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
  };

  if (fullDateTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return new Date(date).toLocaleDateString(language, options);
};

export const formatPrice = (
  price: number,
  currency: string,
  language: string
) => {
  return price.toLocaleString(language, {
    style: "currency",
    currency,
  });
};

export const getShowName = (show: Show, language: Language) => {
  if (show.name) return show.name;
  if (show.date.start) return formatDate(show.date.start, language);
  return show.id;
};

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const getCurrencySymbol = (currency: string) => {
  return (0)
    .toLocaleString("nl", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace("0", "");
};

export const getThousandsSeparator = (locale: "nl" | "fr" | "en") => {
  // return '.' or ',' based on the locale
  return new Intl.NumberFormat(locale).format(1000).charAt(1);
};

export const getDecimalSeparator = (locale: "nl" | "fr" | "en") => {
  // return '.' or ',' based on the locale
  return new Intl.NumberFormat(locale).format(0.1).charAt(1);
};
