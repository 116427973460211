import styles from "./ShowsTable.module.scss";
import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  SortableTableBody,
  SortableTableBodyInner,
  SortableTableRow,
  TableCell,
  ProgressBar,
  Text,
  Toggle,
  SortableTableRowDrag,
  useAuth,
} from "@eventsquare/uikit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { Show } from "@type/shows";

import { stopPropagation, getShowName, formatDate } from "@lib/helpers";
import { useState } from "react";
import { Api } from "@lib/api";

interface ShowsTableProps {
  shows: Show[];
  setShows: React.Dispatch<React.SetStateAction<Show[]>>;
}

const Colgroup = () => {
  return (
    <colgroup className={styles.showsColgroup}>
      <col className={styles.colName} />
      <col className={styles.colProgress} />
      <col className={styles.colActive} />
      <col className={styles.colDraghandler} />
    </colgroup>
  );
};

export const ShowsTable = (props: ShowsTableProps) => {
  const { shows, setShows } = props;

  const navigate = useNavigate();
  const { user } = useAuth();
  const { eventId, editionId } = useParams();

  const [draggingShow, setDraggingShow] = useState<Show | null>(null);

  const onDragStart = (e: DragStartEvent) => {
    const { active } = e;
    const s = shows.find(({ id }) => id === active.id);
    setDraggingShow(s ?? null);
  };

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id && over?.id && active.id !== over?.id) {
      // make api call to update sorting
      setShows((list) => {
        const oldIndex = list.findIndex(({ id }) => id === active.id);
        const newIndex = list.findIndex(({ id }) => id === over.id);
        const arr = arrayMove(list, oldIndex, newIndex);
        updateSorting(arr);
        return arr;
      });
    }

    setDraggingShow(null);
  };

  const onDragCancel = () => {
    setDraggingShow(null);
  };

  const updateSorting = async (sortedArr: Show[]) => {
    try {
      await Api.post(`/editions/${editionId}/shows/sort`, {
        shows: sortedArr.map((show) => show.id),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getProgressBarColor = (show: Show) => {
    const { issued, pending, limit } = show.attendees;
    if (issued + pending > limit) return "error";
    if (show.status === "soldout") return "info";
    if (show.status === "low") return "warning";
    if (show.status === "not_available") return "disabled";
    return "success";
  };

  const toggleShow = async (show: Show) => {
    const showsBeforeUpdate = shows;
    const updatedShows = shows.map((s) => {
      // TODO :
      if (s.id === show.id)
        return { ...s, active: !show.active ? 1 : (0 as 1 | 0) };
      return s;
    });
    setShows(updatedShows);

    try {
      await Api.put(`/shows/${show.id}`, {
        active: !show.active,
      });
    } catch (error) {
      console.error(error);
      setShows(showsBeforeUpdate);
    }
  };

  return (
    <Table clickable scrollable>
      <Colgroup />
      <TableHead>
        <TableRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Persons</TableHeadCell>
          <TableHeadCell hideMobile>Active</TableHeadCell>
          <TableHeadCell hideMobile />
        </TableRow>
      </TableHead>

      <SortableTableBody
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      >
        <SortableTableBodyInner items={shows}>
          {shows.map((show, index) => {
            const showUrl = `/events/${eventId}/editions/${editionId}/shows/${show.id}`;
            return (
              <SortableTableRow
                id={show.id}
                key={show.id}
                onClick={() => navigate(showUrl)}
              >
                <TableCell>
                  <Text variant="h6" noMargin>
                    <Link to={showUrl} onClick={stopPropagation}>
                      {getShowName(show, user!.language)}
                    </Link>
                  </Text>
                  {show.name && show.date.start && (
                    <Text variant="small" noMargin>
                      {formatDate(
                        show.date.start,
                        user!.language,
                        show.date.hide_hours ? false : true
                      )}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  <ProgressBar
                    total={show.attendees.limit}
                    value={show.attendees.issued}
                    pending={show.attendees.pending}
                    color={getProgressBarColor(show)}
                    badge={show.status}
                    index={index}
                  />
                </TableCell>
                <TableCell textAlign="right" hideMobile>
                  <div
                    className={styles.toggleWrapper}
                    onClick={stopPropagation}
                  >
                    <Toggle
                      checked={!!show.active}
                      onChange={() => toggleShow(show)}
                      size="small"
                    />
                  </div>
                </TableCell>
              </SortableTableRow>
            );
          })}
        </SortableTableBodyInner>
        <SortableTableRowDrag
          isDragging={!!draggingShow}
          colgroup={<Colgroup />}
        >
          {draggingShow && (
            <>
              <TableCell>
                <Text variant="h6" noMargin>
                  <Link to={draggingShow.id}>
                    {getShowName(draggingShow, user!.language)}
                  </Link>
                </Text>
                {draggingShow.name && draggingShow.date.start && (
                  <Text variant="small" noMargin>
                    {formatDate(
                      draggingShow.date.start,
                      user!.language,
                      draggingShow.date.hide_hours ? false : true
                    )}
                  </Text>
                )}
              </TableCell>
              <TableCell>
                <ProgressBar
                  total={draggingShow.attendees.limit}
                  value={draggingShow.attendees.issued}
                  pending={draggingShow.attendees.pending}
                  color={getProgressBarColor(draggingShow)}
                  badge={draggingShow.status}
                  index={0}
                  animate={false}
                />
              </TableCell>
              <TableCell textAlign="right" hideMobile>
                <Toggle
                  checked={!!draggingShow.active}
                  onChange={() => toggleShow(draggingShow)}
                  size="small"
                  disabled
                />
              </TableCell>
            </>
          )}
        </SortableTableRowDrag>
      </SortableTableBody>
    </Table>
  );
};
