import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./EditionDashboardMap.module.scss";
import {
  AdvancedMarker,
  Map,
  Pin,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useAuth } from "@eventsquare/uikit";

import { useEdition } from "@hooks/useEdition";
import { Api } from "@lib/api";
import esqIcon from "@/assets/esq-icon.svg";

interface Mapdata {
  latitude: string;
  longitude: string;
  weight: number;
}

export const EditionDashboardMap = () => {
  const { edition } = useEdition();
  const { accountId } = useAuth();
  const map = useMap("dashboard-map");
  const [mapdata, setMapdata] = useState<Mapdata[]>([]);

  const fetchMapdata = useCallback(async () => {
    if (!accountId) return;
    try {
      const params = {
        start: edition.stats.start,
        end: edition.stats.end,
        filter: {
          editions: [edition.id],
        },
      };
      const response = await Api.get(
        `/accounts/${accountId}/stats/geography`,
        params
      );
      setMapdata(response.mapdata);
    } catch (error) {
      console.error(error);
    }
  }, [edition.id, edition.stats.end, edition.stats.start, accountId]);

  useEffect(() => {
    fetchMapdata();
  }, [fetchMapdata]);

  useEffect(() => {
    if (!map || !edition.venue) return;

    // if we have edition.venue, move center of the map to the venue location

    const center = new google.maps.LatLng(
      +edition.venue.latitude,
      +edition.venue.longitude
    );
    map.panTo(center);
    map.setZoom(8);
  }, [map, edition.venue]);

  if (!edition) return null;

  return (
    <>
      <div className={styles.map}>
        <Map
          id="dashboard-map"
          defaultCenter={{ lat: 50.85045, lng: 4.34878 }}
          defaultZoom={4}
          mapId="7f05e33c6304d914"
          disableDefaultUI={true}
          zoomControl={true}
          scrollwheel={false}
          disableDoubleClickZoom={false}
        >
          {edition.venue && (
            <AdvancedMarker
              position={{
                lat: edition.venue.latitude,
                lng: edition.venue.longitude,
              }}
              title={edition.venue.name}
            >
              <Pin scale={1} background={"#068cfc"} borderColor={"#068cfc"}>
                <img src={esqIcon} alt="esqIcon" className={styles.pinIcon} />
              </Pin>
            </AdvancedMarker>
          )}
        </Map>
        <Heatmap mapdata={mapdata} />
      </div>
    </>
  );
};

const Heatmap = (props: { mapdata: Mapdata[] }) => {
  const { mapdata } = props;
  const map = useMap("dashboard-map");
  const visualization = useMapsLibrary("visualization");

  const heatmap = useMemo(() => {
    if (!visualization) return null;

    return new google.maps.visualization.HeatmapLayer({
      radius: 15,
      opacity: 1,
      gradient: [
        "rgba(88, 178, 252, 0)",
        "rgba(88, 178, 252, 1)",
        "rgba(154, 209, 255, 1)",
        "rgba(154, 249, 255, 1)",
        "rgba(150, 255, 255, 1)",
        "rgba(255, 255, 255, 1)",
      ],
      maxIntensity: 20,
    });
  }, [visualization]);

  useEffect(() => {
    if (!heatmap) return;

    const data = mapdata.map((point) => {
      const { latitude, longitude, weight } = point;
      return {
        location: new google.maps.LatLng(+latitude, +longitude),
        weight: weight,
      };
    });

    heatmap.setData(data);
  }, [heatmap, mapdata]);

  useEffect(() => {
    if (!heatmap) return;

    heatmap.setMap(map);

    return () => {
      heatmap.setMap(null);
    };
  }, [heatmap, map]);

  return null;
};
