import { useState } from "react";
import styles from "./TypesTable.module.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  ProgressBar,
  SortableTableBody,
  SortableTableBodyInner,
  SortableTableRow,
  SortableTableRowDrag,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { formatPrice, stopPropagation } from "@lib/helpers";

import { useEdition } from "@hooks/useEdition";

import { Type } from "@type/types";

// TODO : Add call for sorting types

interface TypesTableProps {
  typeType: "ticket" | "voucher";
  types: Type[];
  setTypes: React.Dispatch<React.SetStateAction<Type[]>>;
  editionId: string;
  eventId: string;
  updateSorting: (sortedArr: Type[], type: "ticket" | "voucher") => void;
}

const Colgroup = () => {
  return (
    <colgroup className={styles.typesColgroup}>
      <col className={styles.colName} />
      <col className={styles.colPrice} />
      <col className={styles.colProgress} />
      <col className={styles.colDraghandler} />
    </colgroup>
  );
};

export const TypesTable = (props: TypesTableProps) => {
  const { typeType, types, setTypes, eventId, editionId, updateSorting } =
    props;

  const navigate = useNavigate();
  const { event } = useEdition();
  const { user } = useAuth();

  const [draggingType, setDraggingType] = useState<Type | null>(null);

  const onDragStart = (e: DragStartEvent) => {
    const { active } = e;
    const t = types.find(({ id }) => id === active.id);
    setDraggingType(t ?? null);
  };

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id && over?.id && active.id !== over?.id) {
      // make api call to update sorting

      setTypes((list) => {
        const oldIndex = list.findIndex(({ id }) => id === active.id);
        const newIndex = list.findIndex(({ id }) => id === over.id);
        const arr = arrayMove(list, oldIndex, newIndex);
        updateSorting(arr, typeType);
        return arr;
      });
    }
    setDraggingType(null);
  };

  const onDragCancel = () => {
    setDraggingType(null);
  };

  const getProgressBarColor = (type: Type) => {
    const {
      status,
      stock: { total, issued, pending },
    } = type;
    if (issued + pending > total) return "error";
    if (status === "sold_out") return "info";
    if (status === "low") return "warning";
    if (status === "not_available") return "disabled";
    return "success";
  };

  return (
    <>
      <div>
        <Text variant="h4">
          {typeType === "ticket" ? "Toegangstickets" : "Extra's"}
          {types.length >= 1 && (
            <>
              {" "}
              <Badge content={types.length} size="small" />
            </>
          )}
        </Text>
      </div>
      <Table clickable scrollable>
        <Colgroup />
        <TableHead>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell hideMobile textAlign="right">
              Price
            </TableHeadCell>
            <TableHeadCell>Issued</TableHeadCell>
            <TableHeadCell dragHandler />
          </TableRow>
        </TableHead>
        <SortableTableBody
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <SortableTableBodyInner items={types}>
            {types.map((type, index) => {
              const typeUrl = `/events/${eventId}/editions/${editionId}/tickets/${type.id}`;
              return (
                <SortableTableRow
                  id={type.id}
                  color={type.branding.color}
                  key={index}
                  onClick={() => {
                    navigate(typeUrl);
                  }}
                  alignHandler="end"
                >
                  <TableCell width="unset">
                    <Text variant="h6" noMargin>
                      <Link to={typeUrl} onClick={stopPropagation}>
                        {type.name}
                      </Link>
                    </Text>
                  </TableCell>
                  <TableCell hideMobile textAlign="right" width="unset">
                    {formatPrice(type.price, event!.currency, user!.language)}
                  </TableCell>
                  <TableCell width="unset">
                    <ProgressBar
                      total={type.stock.total}
                      value={type.stock.issued}
                      pending={type.stock.pending}
                      color={getProgressBarColor(type)}
                      badge={type.status}
                      index={index}
                    />
                  </TableCell>
                </SortableTableRow>
              );
            })}
          </SortableTableBodyInner>
          <SortableTableRowDrag
            isDragging={!!draggingType}
            color={draggingType?.branding.color}
            colgroup={<Colgroup />}
          >
            {draggingType && (
              <>
                <TableCell>
                  <Text variant="h6" noMargin>
                    <Link to={draggingType.id.toString()}>
                      {draggingType.name}
                    </Link>
                  </Text>
                </TableCell>
                <TableCell hideMobile textAlign="right">
                  {formatPrice(
                    draggingType.price,
                    event!.currency,
                    user!.language
                  )}
                </TableCell>
                <TableCell>
                  <ProgressBar
                    total={draggingType.stock.total}
                    value={draggingType.stock.issued}
                    pending={draggingType.stock.pending}
                    color={getProgressBarColor(draggingType)}
                    badge={draggingType.status}
                    index={0}
                    animate={false}
                  />
                </TableCell>
              </>
            )}
          </SortableTableRowDrag>
        </SortableTableBody>
      </Table>
    </>
  );
};
