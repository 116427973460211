import { createBrowserRouter, Navigate } from "react-router-dom";

import { DummyPage } from "@components/DummyPage";

import { AppLayout } from "@layouts/AppLayout";
import { TopLayout } from "@layouts/TopLayout";
import { EditionLayout } from "@layouts/EditionLayout";

import { Customers } from "@pages/customers";
import { CustomerPage } from "@pages/customers/customerPage";

import { SettingsLayout } from "@pages/settings/layout";
import { SettingsQuestions } from "@pages/settings/questions";
import { SettingsUsers } from "@pages/settings/users";
import { Reservations } from "@pages/reservations/index";

import { Events } from "@pages/events";
import { EventPage } from "@pages/events/eventPage";

import { EditionDashboard } from "@pages/events/editions/dashboard";

import { EditionTypes } from "@pages/events/editions/types";
import { EditionTypesLayout } from "@pages/events/editions/types/layout";
import { EditionTypesDashboard } from "@pages/events/editions/types/dashboard";
import { EditionTypesSettings } from "@pages/events/editions/types/settings";

import { EditionShows } from "@pages/events/editions/shows";
import { EditionShowsLayout } from "@pages/events/editions/shows/layout";

import { EditionOrders } from "@pages/events/editions/orders";

import { EditionInvitations } from "@pages/events/editions/invitations";

import { EditionScans } from "@pages/events/editions/scans";

import { EditionDeals } from "@pages/events/editions/deals";
import { EditionDealsLayout } from "@pages/events/editions/deals/layout";
import { EditionOrdersLayout } from "@pages/events/editions/orders/layout";

import { EditionChannels } from "@pages/events/editions/channels";
import { EditionChannelsLayout } from "@pages/events/editions/channels/layout";

import { EditionReportsLayout } from "@pages/events/editions/reports/layout";

import { EditionSettingsLayout } from "@pages/events/editions/settings/layout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <TopLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="events" replace />,
          },
          {
            path: "events",
            element: <Events />,
          },
          {
            path: "events/create",
            element: <DummyPage title="Event Create" />,
          },
          {
            path: "events/:eventId",
            element: <EventPage />,
          },
          {
            path: "customers",
            element: <Customers />,
          },
          {
            path: "customers/:customerId",
            element: <CustomerPage />,
          },
          {
            path: "orders",
            element: <DummyPage title="Orders" />,
          },
          {
            path: "statistics",
            element: <DummyPage title="Statistics" />,
          },
          {
            path: "settings",
            element: <SettingsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Settings Dashboard" />,
              },
              {
                path: "users",
                element: <SettingsUsers />,
              },
              {
                path: "users/create",
                element: <DummyPage title="Settings User Create" />,
              },
              {
                path: "users/:userId",
                element: <DummyPage title="Settings Users Detail" />,
              },
              // {
              //   path: "support",
              //   element: <DummyPage title="Settings Support" />,
              // },
              {
                path: "integrations",
                element: <DummyPage title="Settings Integrations" />,
              },
              {
                path: "questions",
                element: <SettingsQuestions />,
              },
              {
                path: "questions/create",
                element: <DummyPage title="Settings Questions Create" />,
              },
              {
                path: "questions/:questionId",
                element: <DummyPage title="Settings Questions Detail" />,
              },
              {
                path: "downloads",
                element: <DummyPage title="Settings Downloads" />,
              },
              {
                path: "reservations",
                element: <Reservations />,
              },
              {
                path: "reservations/create",
                element: <DummyPage title="Reservations Create" />,
              },
              {
                path: "reservations/:reservationId",
                element: <DummyPage title="Reservations Detail" />,
              },
              {
                path: "account",
                element: <DummyPage title="Settings Account" />,
              },
              {
                path: "invoicing",
                element: <DummyPage title="Settings Invoicing Data" />,
              },
              {
                path: "bankaccounts",
                element: <DummyPage title="Settings Bankaccounts" />,
              },
              {
                path: "bankaccounts/create",
                element: <DummyPage title="Settings Bankaccount Create" />,
              },
              {
                path: "payouts",
                element: <DummyPage title="Settings Payouts" />,
              },
              {
                path: "payouts/create",
                element: <DummyPage title="Settings Payout Create" />,
              },
              {
                path: "payouts/periodic",
                element: <DummyPage title="Settings Payout Periodic" />,
              },
              {
                path: "invoices",
                element: <DummyPage title="Settings Invoices" />,
              },
              {
                path: "salesinvoices",
                element: <DummyPage title="Settings Salesinvoices" />,
              },
              // {
              //   path: "me",
              //   element: <DummyPage title="Settings Me" />,
              // },
              // {
              //   path: "me/preferences",
              //   element: <DummyPage title="Settings Me Preferences" />,
              // },
            ],
          },
        ],
      },
      {
        path: "events/:eventId/editions/:editionId",
        element: <EditionLayout />,
        children: [
          {
            path: "",
            element: <EditionDashboard />,
          },
          {
            path: "tickets",
            element: <EditionTypes />,
          },
          {
            path: "tickets/create",
            element: <DummyPage title="Create a ticket" />,
          },
          {
            path: "tickets/:ticketId",
            element: <EditionTypesLayout />,
            children: [
              {
                path: "",
                element: <EditionTypesDashboard />,
              },
              {
                path: "settings",
                element: <EditionTypesSettings />,
              },
              {
                path: "layout",
                element: <DummyPage title="Ticket Layyout" />,
              },
              {
                path: "dates",
                element: <DummyPage title="Ticket Dates" />,
              },
              {
                path: "location",
                element: <DummyPage title="Ticket Location" />,
              },
              {
                path: "publish",
                element: <DummyPage title="Ticket Publish" />,
              },
              {
                path: "exports",
                element: <DummyPage title="Ticket Exports" />,
              },
              {
                path: "questions",
                element: <DummyPage title="Ticket Questions" />,
              },
            ],
          },
          {
            path: "products",
            element: <DummyPage title="Products" />,
          },
          {
            path: "orders",
            element: <EditionOrders />,
          },
          {
            path: "orders/:orderId",
            element: <EditionOrdersLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Order Dashboard" />,
              },
              {
                path: "tickets",
                element: <DummyPage title="Order Tickets" />,
              },
              {
                path: "refunds",
                element: <DummyPage title="Order Refunds" />,
              },
            ],
          },
          {
            path: "invitations",
            element: <EditionInvitations />,
          },
          {
            path: "invitations/create",
            element: <DummyPage title="Invitation Create" />,
          },
          {
            path: "invitations/:invitationId",
            element: <DummyPage title="Invitation Details" />,
          },
          {
            path: "scans",
            element: <EditionScans />,
          },
          {
            path: "deals",
            element: <EditionDeals />,
          },
          {
            path: "deals/create",
            element: <DummyPage title="Deal Create" />,
          },
          {
            path: "deals/:dealId",
            element: <EditionDealsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Deal Dashboard" />,
              },
              {
                path: "codes",
                element: <DummyPage title="Deal Codes" />,
              },
              {
                path: "application",
                element: <DummyPage title="Deal Application" />,
              },
            ],
          },
          {
            path: "shows",
            element: <EditionShows />,
          },
          {
            path: "shows/create",
            element: <DummyPage title="Show Create" />,
          },
          {
            path: "shows/:showId",
            element: <EditionShowsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Show Tickets" />,
              },
              {
                path: "seatmap",
                element: <DummyPage title="Show Seatmap" />,
              },
              {
                path: "settings",
                element: <DummyPage title="Show Settings" />,
              },
            ],
          },
          {
            path: "channels",
            element: <EditionChannels />,
          },
          {
            path: "channels/create",
            element: <DummyPage title="Channel Create" />,
          },
          {
            path: "channels/:channelId",
            element: <EditionChannelsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Channel Online" />,
              },
              {
                path: "layout",
                element: <DummyPage title="Channel Layout" />,
              },
              {
                path: "tickets",
                element: <DummyPage title="Channel Tickets" />,
              },
              {
                path: "shows",
                element: <DummyPage title="Channel Shows" />,
              },
              {
                path: "pos",
                element: <DummyPage title="Channel POS" />,
              },
            ],
          },
          {
            path: "reports",
            element: <EditionReportsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Reports Dashboard" />,
              },
              {
                path: "financial",
                element: <DummyPage title="Reports Financial" />,
              },
              {
                path: "orders",
                element: <DummyPage title="Reports Orders" />,
              },
              {
                path: "guestlist",
                element: <DummyPage title="Reports Guestlist" />,
              },
            ],
          },
          {
            path: "settings",
            element: <EditionSettingsLayout />,
            children: [
              {
                path: "",
                element: <DummyPage title="Settings Dashboard" />,
              },
              {
                path: "preferences",
                element: <DummyPage title="Settings Preferences" />,
              },
              {
                path: "layout",
                element: <DummyPage title="Settings Layout" />,
              },
              {
                path: "messages",
                element: <DummyPage title="Settings Messages" />,
              },
              {
                path: "scankeys",
                element: <DummyPage title="Settings Scankeys" />,
              },
              {
                path: "scankeys/:scankeyId",
                element: <DummyPage title="Settings Scankey Detail" />,
              },
              {
                path: "secondary",
                element: <DummyPage title="Settings Secondary" />,
              },
              {
                path: "integrations",
                element: <DummyPage title="Settings Integrations" />,
              },
              {
                path: "dangerzone",
                element: <DummyPage title="Settings Dangerzone" />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
