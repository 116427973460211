import { useTypeContext } from "@context/typecontext";
import { Button, Container, Text } from "@eventsquare/uikit";
import * as yup from "yup";
import {
  Form,
  FormGroup,
  FormLabel,
  FormInput,
  FormMarkdown,
  // FormRadioGroup,
  FormTextArea,
  FormCheckbox,
} from "@eventsquare/uikit/forms";

interface TypeSettingsValues {
  type: "ticket" | "voucher";
  name: string;
  description: string;
  ticket_information: string;
  price: number | null;
  vat: number | null;
  fee_kickback: number | null;
  customer_service_fee: boolean;
  stock: number | null;
  min_pp: number;
  max_pp: number;
  attendees: number;
  attendees_split: boolean;
  do_not_inherit_name: boolean;
  stock_public: boolean;
}

export const EditionTypesSettings = () => {
  const { type } = useTypeContext();

  const initialValues = {
    type: type!.type,
    name: type!.name,
    description: type!.description || "",
    ticket_information: type!.ticket_information || "",
    price: type!.price,
    vat: type!.vat,
    fee_kickback: type!.fee_kickback,
    customer_service_fee: type!.customer.servicefees,
    stock: type!.stock.generator.total,
    min_pp: type!.min_pp,
    max_pp: type!.max_pp,
    attendees: type!.attendees,
    attendees_split: type!.attendees_split,
    do_not_inherit_name: type!.inherit_name,
    stock_public: type!.stock_public,
  } as TypeSettingsValues;

  const yupSchema = yup.object().shape({
    name: yup.string().required(),
  });

  return (
    <Container>
      <Form
        initialValues={initialValues}
        validationSchema={yupSchema}
        onSubmit={(values: unknown) => {
          console.log(values);
        }}
        loading={false}
        maxWidth={800}
      >
        {(context) => {
          const { setFieldValue, values } = context;
          const handleTypeChange = (type: "ticket" | "voucher") => {
            setFieldValue("type", type);
          };

          return (
            <>
              <FormGroup>
                <FormLabel>Type</FormLabel>
                {/* <FormRadioGroup
                name="type"
                options={[
                  { value: "ticket", label: "Ticket" },
                  { value: "voucher", label: "Voucher" },
                ]}
              /> */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1.5rem",
                  }}
                >
                  <div
                    onClick={() => handleTypeChange("ticket")}
                    style={{
                      cursor: "pointer",
                      border: "1px solid",
                      borderRadius: "0.5rem",
                      borderColor:
                        (values as { type: string }).type === "ticket"
                          ? "#068cfc"
                          : "#c8d5e0",
                      padding: "1rem",
                      backgroundColor: "#fcfdfd",
                    }}
                  >
                    <Text variant="h6">Ticket</Text>
                    <Text noMargin variant="small">
                      A ticket is a voucher that represents a reservation for an
                      event, a flight, a concert, etc.
                    </Text>
                  </div>
                  <div
                    onClick={() => handleTypeChange("voucher")}
                    style={{
                      cursor: "pointer",
                      border: "1px solid",
                      borderRadius: "0.5rem",
                      borderColor:
                        (values as { type: string }).type === "voucher"
                          ? "#068cfc"
                          : "#c8d5e0",
                      padding: "1rem",
                      backgroundColor: "#fcfdfd",
                    }}
                  >
                    <Text variant="h6">Voucher</Text>
                    <Text noMargin variant="small">
                      A vouchers represents something else 🥹
                    </Text>
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormInput name="name" />
              </FormGroup>
              <FormGroup>
                <FormLabel>Description</FormLabel>
                <FormMarkdown name="description" />
              </FormGroup>
              <FormGroup>
                <FormLabel>Ticket information</FormLabel>
                <FormTextArea name="ticket_information" />
              </FormGroup>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "3fr 1fr",
                  gap: "1.5rem",
                }}
              >
                <FormGroup>
                  <FormLabel>Price</FormLabel>
                  <FormInput name="price" type="number" prefix="€" />
                </FormGroup>
                <FormGroup>
                  <FormLabel>VAT</FormLabel>
                  <FormInput name="vat" type="number" prefix="%" />
                </FormGroup>
              </div>
              <FormGroup>
                <FormLabel>Fee kickback</FormLabel>
                <FormInput name="fee_kickback" type="number" prefix="€" />
                <Text variant="small">
                  Extra toeslag die bij in de servicekost inbegrepen wordt
                </Text>
              </FormGroup>
              <FormGroup>
                <FormLabel>Service fee</FormLabel>
                <FormCheckbox
                  name="customer_service_fee"
                  label="Ik wens de servicekost door te rekenen naar de ticketkoper"
                />
              </FormGroup>
              {/* <div>Dynamic content price</div> */}
              <FormGroup>
                <FormLabel>Stock</FormLabel>
                <FormInput name="stock" type="number" />
              </FormGroup>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                <FormGroup>
                  <FormLabel>Min. pp</FormLabel>
                  <FormInput name="min_pp" type="number" step={1} min={1} />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Max. pp</FormLabel>
                  <FormInput
                    name="max_pp"
                    type="number"
                    step={1}
                    min={values.min_pp as number}
                  />
                </FormGroup>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                <FormGroup>
                  <FormLabel>Group ticket</FormLabel>
                  <FormInput name="attendees" type="number" min={1} step={1} />
                </FormGroup>

                {(values.attendees as number) > 1 && (
                  <FormGroup>
                    <FormLabel>Split attendees</FormLabel>
                    <FormCheckbox name="attendees_split" label="Split" />
                  </FormGroup>
                )}
              </div>
              <FormGroup>
                <FormLabel>Options</FormLabel>
                <div style={{ marginBottom: "0.5rem" }}>
                  <FormCheckbox
                    name="do_not_inherit_name"
                    label="Do not inherit name"
                  />
                </div>
                <div>
                  <FormCheckbox name="stock_public" label="Stock public" />
                </div>
              </FormGroup>
              <FormGroup>
                <Button type="submit">Save</Button>
              </FormGroup>
            </>
          );
        }}
      </Form>
    </Container>
  );
};
