import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { AuthProvider, ThemeProvider } from "@eventsquare/uikit";
// import { Analytics } from "@vercel/analytics/react";
// import * as Sentry from "@sentry/react";

import { Api } from "@lib/api";

import { router } from "@/router";

// import i18n (needs to be bundled ;))
import "@/i18n";

import "@eventsquare/uikit/css";
import { removeCookie } from "@lib/cookies";

// TODO : Add Sentry
// Sentry.init({
//   dsn: "https://b1bc4c0004c7a6fac07ada80882b5978@o543793.ingest.us.sentry.io/4507406829682688",
//   integrations: [],
// });

const handleAccountChange = () => {
  removeCookie();
  router.navigate("/");
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider
        apiClient={Api}
        environment={
          import.meta.env.VITE_APP_ENVIRONMENT as unknown as
            | "production"
            | "development"
            | "staging"
        }
        onAccountChange={handleAccountChange}
      >
        <RouterProvider router={router} />
        {/* <Analytics mode={import.meta.env.VITE_APP_ENVIRONMENT} /> */}
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
