import { Link, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  PageLoader,
  UIPage,
} from "@eventsquare/uikit";
import {
  FilterBar,
  FilterCheckbox,
  FilterToggle,
} from "@eventsquare/uikit/filters";
import { useCallback, useEffect, useState } from "react";
import { RiCalendar2Line } from "@remixicon/react";

import { Api } from "@lib/api";

import { Show } from "@type/shows";
import { ShowsTable } from "@components/ShowsTable/ShowsTable";

// TODO : Update after sorting
// TODO : Open/close show
// TODO : Hide past shows

export const EditionShows = () => {
  const { editionId } = useParams();

  const [shows, setShows] = useState<Show[]>([]);
  const [pastShowsHidden, setPastShowsHidden] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchshows = useCallback(async () => {
    try {
      setLoading(true);
      const { shows } = await Api.get(`/editions/${editionId}/shows`);
      setShows(shows);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId]);

  useEffect(() => {
    fetchshows();
  }, [fetchshows]);

  return (
    <>
      <UIPage>
        <PageHeader
          title="Shows"
          badge={loading ? undefined : shows.length ?? undefined}
        >
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create show</Button>
            </Link>
            <FilterToggle filterActive={pastShowsHidden} />
          </ButtonGroup>
        </PageHeader>
        <Container>
          <FilterBar>
            <FilterCheckbox
              label="Hide past shows"
              label_checked="Past shows hidden"
              checked={pastShowsHidden}
              onChange={(checked) => setPastShowsHidden(checked)}
            />
          </FilterBar>
          {loading && <PageLoader />}
          {!loading && !shows.length && (
            <Empty
              title="No shows yet"
              lead="Create your first show by clicking the button above"
              icon={<RiCalendar2Line />}
            />
          )}
          {!loading && shows.length > 0 && (
            <ShowsTable shows={shows} setShows={setShows} />
          )}
        </Container>
      </UIPage>
    </>
  );
};
