import { createContext, useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import {
  UIContentMain,
  UIContentTopbar,
  UIContentNavbar,
  NavBarList,
  NavBarListItem,
  Topbar,
  MobileBottomNavbar,
  MobileBottomNavbarPage,
  MobileBottomNavbarItem,
  EditionTopbar,
  UIPage,
  PageLoader,
} from "@eventsquare/uikit";

import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCalendar2Line,
  RiCheckboxMultipleBlankLine,
  RiFileChartLine,
  RiFundsLine,
  RiHome2Line,
  RiMailSendLine,
  RiQrCodeLine,
  RiSettings3Line,
  RiTicketLine,
  RiVipCrownLine,
} from "@remixicon/react";
import { Api } from "@lib/api";

import { Event } from "@type/events";
import { Edition } from "@type/editions";

export interface EditionContextInterface {
  event: Event;
  edition: Edition;
}

export const EditionContext = createContext<
  EditionContextInterface | undefined
>(undefined);

export const EditionLayout = () => {
  const { eventId, editionId } = useParams();

  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [edition, setEdition] = useState<Edition | undefined>(undefined);

  const fetchEdition = useCallback(async () => {
    if (!eventId || !editionId) {
      return;
    }
    try {
      setLoading(true);
      const { event } = await Api.get(`/events/${eventId}`);
      setEvent(event);
      const { edition } = await Api.get(`/editions/${editionId}`);
      setEdition(edition);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [eventId, editionId]);

  useEffect(() => {
    fetchEdition();
  }, [fetchEdition]);

  // TODO : in this file set mobile navigation for editions / or is this secondary navigation?
  // TODO : Set event and edition in context
  // TODO : move types to types folder

  const location = useLocation();
  const path = location.pathname.split("/")[5];

  if (loading || !event || !edition) {
    return (
      <UIPage>
        <PageLoader />
      </UIPage>
    );
  }

  return (
    <EditionContext.Provider
      value={{
        event,
        edition,
      }}
    >
      <UIContentTopbar>
        <Topbar>
          <EditionTopbar
            event={event.name}
            edition={edition.name ?? undefined}
            type={event.type}
            translations={{
              store_visit: "Visit store",
              store_test: "Test store",
              share: "Share",
            }}
          />
        </Topbar>
      </UIContentTopbar>

      <UIContentMain>
        <UIContentNavbar>
          <NavBarList>
            <Link to="">
              <NavBarListItem
                active={path === undefined || path === ""}
                label="Dashboard"
                icon={RiHome2Line}
              />
            </Link>
            <Link to="orders">
              <NavBarListItem
                active={path === "orders"}
                label="Orders"
                icon={RiFundsLine}
              />
            </Link>
            <Link to="tickets">
              <NavBarListItem
                active={path === "tickets"}
                label="Tickets"
                icon={RiTicketLine}
                badge={edition.has.types ?? undefined}
              />
            </Link>
            <Link to="invitations">
              <NavBarListItem
                active={path === "invitations"}
                label="Invitations"
                icon={RiMailSendLine}
                // badge={undefined}
              />
            </Link>
            <Link to="shows">
              <NavBarListItem
                active={path === "shows"}
                label="Shows"
                icon={RiCalendar2Line}
                badge={edition.has.shows ?? undefined}
              />
            </Link>
            <Link to="scans">
              <NavBarListItem
                active={path === "scans"}
                icon={RiQrCodeLine}
                label="Scanning"
              />
            </Link>
            <Link to="reports">
              <NavBarListItem
                active={path === "reports"}
                icon={RiFileChartLine}
                label="Reports"
              />
            </Link>
            <Link to="deals">
              <NavBarListItem
                active={path === "deals"}
                icon={RiVipCrownLine}
                label="Deals"
              />
            </Link>
            <Link to="channels">
              <NavBarListItem
                active={path === "channels"}
                icon={RiCheckboxMultipleBlankLine}
                label="Channels"
              />
            </Link>
            <Link to="settings">
              <NavBarListItem
                active={path === "settings"}
                icon={RiSettings3Line}
                label="Settings"
              />
            </Link>
          </NavBarList>
        </UIContentNavbar>
        <div
          style={{
            flex: "1 1 0%",
            overflow: "scroll",
            display: "flex",
          }}
        >
          <Outlet />
        </div>
        <MobileBottomNavbar page={page} totalPages={3}>
          <MobileBottomNavbarPage>
            <Link to="">
              <MobileBottomNavbarItem
                active={path === undefined || path === ""}
                label="Dashboard"
                icon={RiHome2Line}
              />
            </Link>
            <Link to="orders">
              <MobileBottomNavbarItem
                active={path === "orders"}
                icon={RiFundsLine}
                label="Orders"
              />
            </Link>
            <Link to="tickets">
              <MobileBottomNavbarItem
                active={path === "tickets"}
                label="Tickets"
                icon={RiTicketLine}
              />
            </Link>
            <Link to="invitations">
              <MobileBottomNavbarItem
                active={path === "invitations"}
                icon={RiMailSendLine}
                label="Invitations"
              />
            </Link>
            <MobileBottomNavbarItem
              icon={RiArrowRightLine}
              label="More"
              onClick={() => setPage(page + 1)}
            />
          </MobileBottomNavbarPage>
          <MobileBottomNavbarPage>
            <MobileBottomNavbarItem
              icon={RiArrowLeftLine}
              label="Back"
              onClick={() => setPage(page - 1)}
            />
            <Link to="shows">
              <MobileBottomNavbarItem
                active={path === "shows"}
                label="Shows"
                icon={RiCalendar2Line}
              />
            </Link>
            <Link to="scans">
              <MobileBottomNavbarItem
                active={path === "scans"}
                icon={RiQrCodeLine}
                label="Scanning"
              />
            </Link>
            <Link to="reports">
              <MobileBottomNavbarItem
                active={path === "reports"}
                icon={RiFileChartLine}
                label="Reports"
              />
            </Link>
            <MobileBottomNavbarItem
              icon={RiArrowRightLine}
              label="More"
              onClick={() => setPage(page + 1)}
            />
          </MobileBottomNavbarPage>
          <MobileBottomNavbarPage>
            <MobileBottomNavbarItem
              icon={RiArrowLeftLine}
              label="Back"
              onClick={() => setPage(page - 1)}
            />
            <Link to="deals">
              <MobileBottomNavbarItem
                active={path === "deals"}
                icon={RiVipCrownLine}
                label="Deals"
              />
            </Link>
            <Link to="channels">
              <MobileBottomNavbarItem
                active={path === "channels"}
                icon={RiCheckboxMultipleBlankLine}
                label="Channels"
              />
            </Link>
            <Link to="settings">
              <MobileBottomNavbarItem
                active={path === "settings"}
                icon={RiSettings3Line}
                label="Settings"
              />
            </Link>
          </MobileBottomNavbarPage>
        </MobileBottomNavbar>
      </UIContentMain>
    </EditionContext.Provider>
  );
};
