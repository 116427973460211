import styles from "./dashboard.module.scss";
import {
  Container,
  Count,
  Tile,
  UIPage,
  ProgressBar,
  Button,
} from "@eventsquare/uikit";
import { createSearchParams, Link, useParams } from "react-router-dom";
import { RiGroupLine } from "@remixicon/react";

import { useTypeContext } from "@context/typecontext";

export const EditionTypesDashboard = () => {
  const { type } = useTypeContext();
  const { eventId, editionId } = useParams();

  if (!type) return null;

  return (
    <>
      <UIPage>
        <Container>
          <div className={styles.dashboard}>
            <div className={styles.dashboardHero}>
              <div className={styles.dashboardHero__top}>
                <h3 className={styles.dashboardHero__title}>Tickets issued</h3>
                {type.stock.issued > 0 && (
                  <p className={styles.dashboardHero__guestlistLink}>
                    <Link
                      to={{
                        pathname: `/events/${eventId}/editions/${editionId}/reports/guestlist`,
                        search: createSearchParams({
                          tickets: [type.id],
                        }).toString(),
                      }}
                    >
                      {/* <span>
                        <RiGroupLine />
                      </span>
                      Guestlist */}
                      <Button variant="outline" size="tiny">
                        <RiGroupLine /> Guestlist
                      </Button>
                    </Link>
                  </p>
                )}
              </div>
              <h1 className={styles.dashboardHero__issued}>
                <Count end={type.stock.issued} />
              </h1>
              <div>
                <ProgressBar
                  size="medium"
                  value={type.stock.issued}
                  total={type.stock.total}
                  pending={type.stock.pending}
                  color={"info"}
                  showLabel={false}
                />
              </div>
            </div>

            <div className={styles.dashboardGrid}>
              <Tile
                title="Pending"
                value={<Count end={type.stock.pending} />}
              />
              <Tile
                title="Available"
                value={<Count end={type.stock.available} />}
              />
              <Tile title="Total" value={<Count end={type.stock.total} />} />
            </div>
          </div>
        </Container>
      </UIPage>
    </>
  );
};
