import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  PageHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  UIPage,
} from "@eventsquare/uikit";
import { RiCheckLine, RiDeleteBin2Line, RiEdit2Line } from "@remixicon/react";

const questionsDemo = [
  {
    id: 1,
    question: "Are you happy?",
    active: true,
    type: "checkbox",
  },
  {
    id: 2,
    question: "What do you you want to eat?",
    active: false,
    type: "select",
  },
  {
    id: 3,
    question: "Please tell us your favorite joke",
    active: false,
    type: "text",
  },
  {
    id: 4,
    question: "When is your birthday?",
    active: true,
    type: "date",
  },
];

export const SettingsQuestions = () => {
  const navigate = useNavigate();
  return (
    <>
      <UIPage>
        <PageHeader title="Settings Questions">
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create new question</Button>
            </Link>
          </ButtonGroup>
        </PageHeader>
        <Container>
          <Table scrollable clickable>
            <TableHead>
              <TableRow>
                <TableHeadCell>Question</TableHeadCell>
                <TableHeadCell width="2rem">Active</TableHeadCell>
                <TableHeadCell width="8rem" hideMobile></TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionsDemo.map((question) => {
                return (
                  <TableRow
                    key={question.id}
                    onClick={() => navigate(`${question.id}`)}
                  >
                    <TableCell>
                      <Link to={`${question.id}`}>{question.question}</Link>
                    </TableCell>
                    <TableCell>
                      {question.active ? (
                        <RiCheckLine color={"var(--theme-color-success)"} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell hideMobile>
                      <ButtonGroup noMargin noWrap>
                        <Button
                          variant="outline"
                          icon
                          onClick={() => navigate(`${question.id}`)}
                        >
                          <RiEdit2Line />
                        </Button>
                        <Button
                          variant="outline"
                          icon
                          onClick={() =>
                            alert(
                              "Are you sure you want to delete this question?"
                            )
                          }
                        >
                          <RiDeleteBin2Line />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Container>
      </UIPage>
    </>
  );
};
