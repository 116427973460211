import { useAuth, useTheme } from "@eventsquare/uikit";
import {
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  registerables,
  TimeSeriesScale,
} from "chart.js";
import { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import styles from "./ScansBarChart.module.scss";

import "chartjs-adapter-luxon";

import { getOptions } from "./scanchartOptions";

ChartJS.register(...registerables, TimeSeriesScale);

interface BarChartProps {
  scanData: {
    types: ScansType[];
    meta: {
      decimation: number;
      first_scanned_at: string;
      last_scanned_at: string;
    };
  } | null;
  cumulative: boolean;
  grouped: boolean;
  filterTypes: string[];
}

export interface ScansMeta {
  decimation: number;
  first_scanned_at: string;
  last_scanned_at: string;
}

export interface ScansType {
  branding_color: string;
  graph: Graph[];
  name: string;
  scans: number;
  total: number;
  type: "ticket" | "voucher";
  uid: string;
}

interface Graph {
  scanned_at: string;
  scans: number;
}

// TODO : Add loading state + message if no data

export const ScansBarChart = (props: BarChartProps) => {
  const { scanData, cumulative, grouped, filterTypes } = props;
  const { colorMode } = useTheme();
  const { user } = useAuth();

  const [chartData, setChartData] = useState<ChartData<
    "bar",
    { x: string; y: number }[],
    string
  > | null>(null);

  const formatScanDate = useCallback(
    (date: Date, decimation: number) => {
      const locale = user!.language;
      if (decimation < 21600) {
        return date.toLocaleTimeString(locale, {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      if (decimation < 86400) {
        return date.toLocaleTimeString(locale, {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      if (decimation < 604800) {
        return date.toLocaleTimeString(locale, {
          weekday: "short",
          day: "2-digit",
        });
      }
      return date.toLocaleDateString(locale, {
        month: "short",
        day: "numeric",
      });
    },
    [user]
  );

  const updateChart = useCallback(() => {
    if (!scanData) return;

    const {
      types,
      meta: { decimation },
    } = scanData;

    if (!types || types.length === 0) return;

    if (types.length === 0) {
      setChartData(null);
      return;
    }

    // Find min and max date
    // Let's take the first date as a starting point
    const firstDate: string = types[0]?.graph[0]?.scanned_at;

    if (!firstDate) {
      setChartData(null);
      return;
    }

    const minDate: string = types.reduce((acc: string, type: ScansType) => {
      const minDateType = type.graph.reduce((acc: string, gr: Graph) => {
        return gr.scanned_at < acc ? gr.scanned_at : acc;
      }, firstDate);
      return minDateType < acc ? minDateType : acc;
    }, firstDate);

    const maxDate: string = types.reduce((acc: string, type: ScansType) => {
      const maxDateType = type.graph.reduce((acc: string, gr: Graph) => {
        return gr.scanned_at > acc ? gr.scanned_at : acc;
      }, firstDate);
      return maxDateType > acc ? maxDateType : acc;
    }, firstDate);

    // Let's make datasets but only if we have min and max date
    const datasets: ChartDataset<"bar", { x: string; y: number }[]>[] = [];
    const graphDates: Date[] = [];

    if (minDate && maxDate) {
      // if we have min and max date, let's fill the graphDates array
      let currentDate = new Date(minDate);
      const maxDateDate = new Date(maxDate);
      while (currentDate <= maxDateDate) {
        graphDates.push(currentDate as Date);
        // add decimation in seconds to current date
        currentDate = new Date(currentDate.getTime() + decimation * 1000);
      }
    }

    if (graphDates.length === 0) {
      setChartData(null);
      return;
    }

    // Now we have all dates, let's fill the datasets

    if (grouped) {
      // Make 1 dataset with all scans together from all type (in filter)
      // Loop throug all the dates and find all the scans for that date
      const data: {
        x: string;
        y: number;
      }[] = [];
      let cumulativeScans = 0;
      graphDates.forEach((date) => {
        // Check if type graph has a value for this date
        const scansForDate = types.reduce((acc: number, type: ScansType) => {
          if (filterTypes.length > 0 && !filterTypes.includes(type.uid))
            return acc;
          const typeWithMatchingDate = type.graph.find((item) => {
            return date.getTime() === new Date(item.scanned_at).getTime();
          });
          return acc + (typeWithMatchingDate ? typeWithMatchingDate.scans : 0);
        }, 0);

        cumulativeScans += scansForDate;
        data.push({
          x: formatScanDate(date, decimation),
          y: cumulative ? cumulativeScans : scansForDate,
        });
      });

      // We add this data to the datasets array
      datasets.push({
        backgroundColor: "#068cfc",
        borderRadius: 3.5,
        barThickness: 7,
        borderSkipped: false,
        data: data,
        grouped: false,
      });
    } else {
      // Not grouped
      // Make a dataset for each type
      types.forEach((type: ScansType) => {
        if (filterTypes.length > 0 && !filterTypes.includes(type.uid)) return;
        // This array will be filled with the data for this type
        // x will be the date formatted, y will be the number of scans
        const data: {
          x: string;
          y: number;
        }[] = [];
        let cumulativeScans = 0;
        graphDates.forEach((date) => {
          // Check if type graph has a value for this date
          const typeWithMatchingDate = type.graph.find((item) => {
            return date.getTime() === new Date(item.scanned_at).getTime();
          });
          if (typeWithMatchingDate) {
            // add the scans for this date to the cumulative scans
            cumulativeScans += typeWithMatchingDate.scans;
            // add the date and the scans to the data array
            data.push({
              x: formatScanDate(date, decimation),
              y: cumulative ? cumulativeScans : typeWithMatchingDate.scans,
            });
          } else {
            // if there is no match add an object with zero scans or the cumul from day before to the data array
            data.push({
              x: formatScanDate(date, decimation),
              y: cumulative ? cumulativeScans : 0,
            });
          }
        });
        // We add this data to the datasets array
        datasets.push({
          label: type.name,
          backgroundColor: type.branding_color,
          borderRadius: 3.5,
          barThickness: 7,
          borderSkipped: false,
          data: data,
          grouped: false,
        });
      });
    }

    setChartData({
      labels: [],
      datasets: datasets,
    });
  }, [scanData, grouped, formatScanDate, cumulative, filterTypes]);

  useEffect(() => {
    updateChart();
  }, [updateChart]);

  if (!chartData || !user) return null;

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        <Bar
          data={chartData!}
          options={getOptions(colorMode as "light" | "dark")}
          redraw={false}
          style={{
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
};
