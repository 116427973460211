import { TypeDetails } from "@type/types";
import { useOutletContext } from "react-router-dom";

export type TypeContext = {
  type: TypeDetails | null;
  setType: (type: TypeDetails | null) => void;
  fetchType: () => void;
};

export const useTypeContext = () => {
  return useOutletContext<TypeContext>();
};
