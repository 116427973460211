import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Loader,
  SectionHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { RiDeleteBin2Line, RiRefreshLine } from "@remixicon/react";

import { Api } from "@lib/api";
import { formatDate, getNowInAccountTimezone } from "@lib/helpers";

import { PageLoader } from "@components/PageLoader/PageLoader";
import { DeleteUserInvitationModal } from "@components/modals/DeleteUserInvitationModal/DeleteUserInvitationModal";

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  lastseen_at: string;
}

interface Invitation {
  token: string;
  email: string;
  created_at: string;
  inviter: {
    id: string;
    name: string;
    email: string;
  };
  status: "pending" | "expired";
}

export const SettingsUsers = () => {
  const navigate = useNavigate();
  const { accountId, user: accountUser, account } = useAuth();

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [usersTotal, setUsersTotal] = useState<number | undefined>(undefined);
  const [loadingInvitations, setLoadingInvitations] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [resending, setResending] = useState<boolean>(false);
  const [invitationIsResend, setInvitationIsResend] = useState<
    string | undefined
  >(undefined);
  const [resendError, setResendError] = useState<string | undefined>(undefined);
  const [deleteToken, setDeleteToken] = useState<string | undefined>(undefined);

  const fetchUsers = useCallback(async () => {
    try {
      setLoadingUsers(true);
      const { users, total } = await Api.get(`/accounts/${accountId}/users`);
      setUsers(users);
      setUsersTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUsers(false);
    }
  }, [accountId]);

  const fetchInvitations = useCallback(async () => {
    try {
      setLoadingInvitations(true);
      const { invitations } = await Api.get(
        `/accounts/${accountId}/invitations`
      );
      setInvitations(invitations);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInvitations(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  const formatLastseen = (lastseen: string) => {
    if (!lastseen) return "-";
    // check is lastseen is less than 10 minutes ago
    const lastseenDate = Date.parse(lastseen);
    const now = getNowInAccountTimezone(account!.timezone);
    const diff = now - lastseenDate;

    if (diff < 600000) {
      // 10 minutes
      return "Online";
    }

    return formatDate(lastseen, accountUser!.language);
  };

  const resendInvitation = async (token: string) => {
    setResending(true);
    setInvitationIsResend(undefined);
    setResendError(undefined);
    try {
      const response = await Api.post(
        `/accounts/${accountId}/invitations/${token}/actions`,
        {
          type: "resend",
        }
      );

      if (response.success) {
        setInvitationIsResend(token);
      }
    } catch (error) {
      console.error(error);
      setResendError("something_went_wrong");
    } finally {
      setResending(false);
    }
  };

  return (
    <>
      <UIPage>
        {loadingUsers || loadingInvitations ? <PageLoader /> : null}
        {!loadingUsers && !loadingInvitations && (
          <>
            {users.length > 0 && (
              <>
                <SectionHeader title="Administrators" badge={usersTotal}>
                  <ButtonGroup noMargin>
                    <Link to="create">
                      <Button variant="outline">Add administrator</Button>
                    </Link>
                  </ButtonGroup>
                </SectionHeader>
                <Container>
                  <Table scrollable clickable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell hideTablet>Email</TableHeadCell>
                        <TableHeadCell>Last seen</TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => {
                        return (
                          <TableRow
                            key={user.id}
                            onClick={() => navigate(user.id)}
                          >
                            <TableCell>
                              <Text variant="h6" noMargin>
                                <Link
                                  to={user.id}
                                >{`${user.firstname} ${user.lastname}`}</Link>
                                {user.id == accountUser?.id && (
                                  <Badge
                                    size="small"
                                    content={"me"}
                                    color="success"
                                    marginLeft
                                  />
                                )}
                              </Text>
                            </TableCell>
                            <TableCell hideTablet>{user.email}</TableCell>
                            <TableCell>
                              {formatLastseen(user.lastseen_at)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Container>
              </>
            )}
            {invitations.length > 0 && (
              <>
                <SectionHeader title="Invitations" badge={invitations.length} />
                <Container>
                  {invitationIsResend && (
                    <Alert
                      type="success"
                      message={`Invitation resent to ${
                        invitations.find(
                          (invitation) =>
                            invitation.token === invitationIsResend
                        )?.email
                      } `}
                      onClick={() => setInvitationIsResend(undefined)}
                    />
                  )}
                  {resendError && (
                    <Alert
                      type="error"
                      message={resendError}
                      onClick={() => setResendError(undefined)}
                    />
                  )}
                  <Table scrollable clickable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Email</TableHeadCell>
                        <TableHeadCell hideTablet>Sent</TableHeadCell>
                        <TableHeadCell hideTablet>Invited by</TableHeadCell>
                        <TableHeadCell width="8rem"></TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitations.map((invitation) => {
                        return (
                          <TableRow key={invitation.token}>
                            <TableCell>{invitation.email}</TableCell>
                            <TableCell hideTablet>
                              {formatDate(
                                invitation.created_at,
                                accountUser!.language,
                                true
                              )}
                            </TableCell>
                            <TableCell hideTablet>
                              {invitation.inviter.name}
                            </TableCell>
                            <TableCell>
                              <ButtonGroup noMargin noWrap>
                                <Button
                                  variant="outline"
                                  icon
                                  onClick={() =>
                                    resendInvitation(invitation.token)
                                  }
                                  disabled={resending}
                                >
                                  {resending ? (
                                    <Loader size="tiny" />
                                  ) : (
                                    <RiRefreshLine />
                                  )}
                                </Button>
                                <Button
                                  variant="outline"
                                  icon
                                  onClick={() =>
                                    setDeleteToken(invitation.token)
                                  }
                                >
                                  <RiDeleteBin2Line />
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Container>
              </>
            )}
          </>
        )}
        <DeleteUserInvitationModal
          token={deleteToken}
          setTokenUndefined={() => setDeleteToken(undefined)}
          afterDelete={fetchInvitations}
        />
      </UIPage>
    </>
  );
};
