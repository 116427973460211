import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  PageLoader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import {
  //   FilterBar,
  //   FilterCheckbox,
  FilterSearch,
  //   FilterSelect,
  //   FilterToggle,
} from "@eventsquare/uikit/filters";
import { useCallback, useEffect, useState } from "react";
import { RiFundsLine } from "@remixicon/react";

import { PaymentMethodType } from "@type/paymentmethods";

import { Api } from "@lib/api";
import { formatDate, formatPrice, stopPropagation } from "@lib/helpers";

interface Order {
  boxoffice: boolean;
  cancelled: boolean;
  channel: {
    id: string;
    name: string;
  };
  completed: boolean;
  created_at: string;
  currency: "EUR" | "GBP";
  customer: {
    email: string;
    firstname: string;
    id: string;
    language: "nl" | "fr" | "en";
    lastname: string;
  };
  expired: boolean;
  has: {
    refunds: number;
  };
  id: string;
  invitation: boolean;
  partner: null;
  payment: {
    id: string;
    identifier: PaymentMethodType;
    icons: string[];
    logo: string;
    name: string;
    payment_id: string;
  } | null;
  pos: boolean;
  price: number;
  processed: boolean;
  processed_at: string | null;
  reference: string;
  status: "OK" | "ERROR";
  testmode: boolean;
  under_review: boolean;
}

const ORDERS_ON_PAGE = 25;

// TODO : Implement export
// TODO : Implement hide failed
// TODO : Implement filter payment

export const EditionOrders = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { eventId, editionId } = useParams();
  const { user } = useAuth();

  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersTotal, setOrdersTotal] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get("search") ?? ""
  );
  //   const [filterpayment, setFilterPayment] = useState<string[]>([]);
  //   const [hideFailed, setHideFailed] = useState<boolean>(false);

  const fetchOrders = useCallback(async () => {
    const activePage = searchParams.get("page")
      ? parseInt(searchParams.get("page")!)
      : 1;
    const search = searchParams.get("search") ?? "";
    try {
      setLoading(true);

      const params: {
        limit: number;
        offset: number;
        search?: string;
        // payment?: string[];
        // hide_failed?: boolean;
      } = {
        limit: ORDERS_ON_PAGE,
        offset: (activePage - 1) * ORDERS_ON_PAGE,
      };

      if (search) {
        params["search"] = search;
      }

      const { orders, total } = await Api.get(
        `/editions/${editionId}/orders`,
        params
      );
      setOrders(orders);
      setOrdersTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId, searchParams]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentParams = new URLSearchParams(searchParams);
      if (searchValue === "") {
        currentParams.delete("search");
        currentParams.delete("page");
        setSearchParams(currentParams);
        return;
      }
      currentParams.set("search", searchValue);
      currentParams.delete("page");
      setSearchParams(currentParams);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  //   const handleFilterPayment = (value: string) => {
  //     if (value === "") {
  //       setFilterPayment([]);
  //       return;
  //     }
  //     if (filterpayment.includes(value)) {
  //       setFilterPayment(filterpayment.filter((item) => item !== value));
  //     } else {
  //       setFilterPayment([...filterpayment, value]);
  //     }
  //   };

  const getCustomerName = (customer: Order["customer"]) => {
    const { firstname, lastname } = customer;

    if (!firstname && !lastname) {
      return "Unknown";
    }
    return `${firstname} ${lastname}`.trim();
  };

  const getStatusColor = (status: Order["status"]) => {
    if (status === "OK") {
      return "success";
    }
    if (status === "ERROR") {
      return "error";
    }
    return "disabled";
  };

  const getStatusMessage = (status: Order["status"]) => {
    if (status === "OK") {
      return "Success";
    }
    if (status === "ERROR") {
      return "Failed";
    }
    return "Pending";
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Orders" badge={ordersTotal || undefined}>
          {!!searchParams.get("page") ||
          !!searchParams.get("search") ||
          !!ordersTotal ? (
            <ButtonGroup noMargin>
              <FilterSearch value={searchValue} setValue={setSearchValue} />
              <Button onClick={() => console.log("Export")} variant="outline">
                Export
              </Button>
              {/* <FilterToggle filterActive={} /> */}
            </ButtonGroup>
          ) : null}
        </PageHeader>
        <Container>
          {/* <FilterBar>
            <FilterSelect
              multi
              label="Payment method"
              value={filterpayment}
              onChange={handleFilterPayment}
              options={[
                {
                  title: "Bancontact",
                  value: "bancontact",
                },
                {
                  title: "Bitcoin",
                  value: "bitcoin",
                },
                {
                  title: "Creditcard",
                  value: "creditcard",
                },
                {
                  title: "Ideal",
                  value: "ideal",
                },
                {
                  title: "Mastercard",
                  value: "mastercard",
                },
                {
                  title: "Visa",
                  value: "visa",
                },
                {
                  title: "Banktransfer",
                  value: "banktransfer",
                },
              ]}
            /> 
            <FilterCheckbox
              label="Hide failed"
              label_checked="Failed hidden"
              checked={hideFailed}
              onChange={setHideFailed}
            />
          </FilterBar> */}
          {loading && <PageLoader />}
          {!loading && orders && orders.length === 0 && (
            <Empty
              title={"No orders found"}
              lead={
                !!searchParams.get("page") || !!searchParams.get("search")
                  ? "No orders match your search"
                  : "Your orders will appear here"
              }
              icon={<RiFundsLine />}
            />
          )}
          {!loading && orders && orders.length > 0 && (
            <>
              <Table scrollable clickable>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell textAlign="right">Amount</TableHeadCell>
                    <TableHeadCell width="2rem" hideTablet />
                    <TableHeadCell>Status</TableHeadCell>
                    <TableHeadCell>Reference</TableHeadCell>
                    <TableHeadCell>Date</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => {
                    const createdAt = formatDate(
                      order.created_at,
                      user!.language,
                      true
                    );
                    const orderUrl = `/events/${eventId}/editions/${editionId}/orders/${order.id}`;
                    return (
                      <TableRow
                        key={order.id}
                        onClick={() => navigate(orderUrl)}
                      >
                        <TableCell>
                          <Text variant="h6" noMargin>
                            <Link to={orderUrl} onClick={stopPropagation}>
                              {getCustomerName(order.customer)}
                              {order.pos ? (
                                <Badge
                                  content="pos"
                                  size="small"
                                  marginLeft
                                  color="success"
                                />
                              ) : (
                                ""
                              )}
                              {order.testmode ? (
                                <Badge
                                  content="testmode"
                                  size="small"
                                  marginLeft
                                  color="warning"
                                />
                              ) : (
                                ""
                              )}
                            </Link>
                          </Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          {formatPrice(
                            order.price,
                            order.currency,
                            user!.language
                          )}
                        </TableCell>
                        <TableCell hideTablet>
                          {order.payment && (
                            <img
                              src={order.payment.logo}
                              alt={order.payment.name}
                              style={{ width: "2rem", display: "block" }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Badge
                            content={getStatusMessage(order.status)}
                            size="small"
                            color={getStatusColor(order.status)}
                          />
                        </TableCell>
                        <TableCell>{order.reference}</TableCell>
                        <TableCell>
                          {createdAt.charAt(0).toUpperCase() +
                            createdAt.slice(1)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <Pagination
                activePage={
                  searchParams.get("page")
                    ? parseInt(searchParams.get("page")!)
                    : 1
                }
                itemsTotal={ordersTotal ?? 0}
                itemsPerPage={ORDERS_ON_PAGE}
                setActivePage={(page: number) => {
                  const currentParams = new URLSearchParams(searchParams);
                  if (page === 1) {
                    currentParams.delete("page");
                    setSearchParams(currentParams);
                    return;
                  }
                  currentParams.set("page", page.toString());
                  setSearchParams(currentParams);
                }}
                translations={{
                  first_page: "First",
                  last_page: "Last",
                  active_page: "page {{activePage}} from {{totalPages}}",
                }}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
