import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  PageLoader,
  UIPage,
} from "@eventsquare/uikit";
import { useCallback, useEffect, useState } from "react";
import { Api } from "@lib/api";

import { TypeContext } from "../../../../context/typecontext";

import { TypeDetails } from "@type/types";

export const EditionTypesLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[7];

  const { ticketId } = useParams();

  const [type, setType] = useState<TypeDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchType = useCallback(async () => {
    if (!ticketId) return;
    try {
      setLoading(true);
      const { type } = await Api.get(`/types/${ticketId}`);
      setType(type);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchType();
  }, [fetchType]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <UIPage>
        <PageHeader title={type?.name ?? ""} />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>
            <Link to="settings">
              <MobileTopNavbarItem
                label="Settings"
                active={path === "settings"}
              />
            </Link>
            <Link to="layout">
              <MobileTopNavbarItem label="Layout" active={path === "layout"} />
            </Link>
            <Link to="dates">
              <MobileTopNavbarItem label="Dates" active={path === "dates"} />
            </Link>
            <Link to="location">
              <MobileTopNavbarItem
                label="Location"
                active={path === "location"}
              />
            </Link>
            <Link to="publish">
              <MobileTopNavbarItem
                label="Publish"
                active={path === "publish"}
              />
            </Link>
            <Link to="exports">
              <MobileTopNavbarItem
                label="Exports"
                active={path === "exports"}
              />
            </Link>
            <Link to="questions">
              <MobileTopNavbarItem
                label="Questions"
                active={path === "questions"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet
          context={
            {
              type,
              setType,
              fetchType,
            } satisfies TypeContext
          }
        />
      </UIPage>
    </>
  );
};
