import { useCallback, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import {
  FilterBar,
  FilterCheckbox,
  FilterSearch,
  FilterToggle,
} from "@eventsquare/uikit/filters";
import { RiDownload2Line, RiGroupLine } from "@remixicon/react";

import { Api } from "@lib/api";

import { PageLoader } from "@components/PageLoader/PageLoader";
import { formatDate, formatPrice } from "@lib/helpers";

const CUSTOMERS_ON_PAGE = 100;

interface Customer {
  account_orders: {
    count: number;
    first_order: string;
    last_order: string;
    revenue: number;
  };
  // account_pivot: { ... };
  id: string;
  language: "nl" | "fr" | "en";
  email: string;
  firstname: string;
  lastname: string;
}

// TODO : Format date with i18n language
// TODO : Implement hide without orders
// TODO : Implement export

export const Customers = () => {
  const navigate = useNavigate();
  const { accountId, account, user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[] | null>(null);
  const [customersTotal, setCustomersTotal] = useState<number | undefined>(
    undefined
  );
  const [hideWithoutOrders, setHideWithoutOrders] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(
    searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1
  );

  const fetchCustomers = useCallback(async () => {
    setLoading(true);

    try {
      const params = {
        limit: CUSTOMERS_ON_PAGE,
        page: activePage,
        search: search,
        hide_without_orders: hideWithoutOrders ? 1 : 0,
      };
      const { customers, meta } = await Api.get(
        `/accounts/${accountId}/customers`,
        params
      );
      setCustomers(customers);
      setCustomersTotal(meta.pagination.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [activePage, search, hideWithoutOrders, accountId]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    setActivePage(1);
  }, [hideWithoutOrders]);

  useEffect(() => {
    setSearchParams({ ...searchParams, page: activePage.toString() });
  }, [activePage]);

  const getCustomerName = (customer: Customer) => {
    const { firstname, lastname } = customer;

    if (!firstname && !lastname) {
      return "-";
    }
    return `${firstname} ${lastname}`.trim();
  };

  if (!account) return null;

  return (
    <>
      <UIPage>
        <PageHeader title="Customers">
          <ButtonGroup noMargin>
            <FilterSearch value={search} setValue={setSearch} />
            <Button onClick={() => console.log("Export")} variant="outline">
              <RiDownload2Line />
              Export
            </Button>
            <FilterToggle filterActive={hideWithoutOrders} />
          </ButtonGroup>
        </PageHeader>
        <Container>
          <FilterBar>
            <FilterCheckbox
              label="Hide without orders"
              label_checked="Without orders hidden"
              checked={hideWithoutOrders}
              onChange={() => setHideWithoutOrders(!hideWithoutOrders)}
            />
          </FilterBar>
          {loading && <PageLoader />}
          {!loading && customers && customers.length === 0 && (
            <Empty
              title={"No customers found"}
              lead={"No customers found"}
              icon={<RiGroupLine />}
            />
          )}
          {!loading && customers && customers.length > 0 && (
            <>
              <Table scrollable clickable>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Email</TableHeadCell>
                    <TableHeadCell hideTablet>First Order</TableHeadCell>
                    <TableHeadCell hideTablet>Last Order</TableHeadCell>
                    <TableHeadCell textAlign="right" hideMobile>
                      Orders
                    </TableHeadCell>
                    <TableHeadCell textAlign="right">Spend</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => {
                    const { first_order, last_order, count, revenue } =
                      customer.account_orders;
                    return (
                      <TableRow
                        key={customer.id}
                        onClick={() => navigate(`/customers/${customer.id}`)}
                      >
                        <TableCell>
                          <Text variant="h6" noMargin>
                            <Link to={`/customers/${customer.id}`}>
                              {getCustomerName(customer)}
                            </Link>
                          </Text>
                        </TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell hideTablet>
                          {first_order
                            ? formatDate(first_order, user!.language, true)
                            : "-"}
                        </TableCell>
                        <TableCell hideTablet>
                          {last_order
                            ? formatDate(last_order, user!.language, true)
                            : "-"}
                        </TableCell>
                        <TableCell textAlign="right" hideMobile>
                          {count}
                        </TableCell>
                        <TableCell textAlign="right">
                          {revenue
                            ? formatPrice(
                                revenue,
                                account.currency,
                                user!.language
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                activePage={activePage}
                itemsTotal={customersTotal ?? 0}
                itemsPerPage={CUSTOMERS_ON_PAGE}
                setActivePage={setActivePage}
                translations={{
                  first_page: "First page",
                  last_page: "Last page",
                  active_page: "Page {{ activePage }} from {{ totalPages }}",
                }}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
