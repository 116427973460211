import { Link, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Count,
  Empty,
  PageHeader,
  PageLoader,
  ProgressBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  Tile,
  Toggle,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { FilterBar, FilterSelect } from "@eventsquare/uikit/filters";
import { useCallback, useEffect, useState } from "react";
import {
  RiExpandUpDownLine,
  RiRefreshLine,
  RiSettings3Line,
  RiStackLine,
} from "@remixicon/react";

import { Api } from "@lib/api";

import { TileGrid } from "@components/TileGrid/TileGrid";
import { ScansBarChart } from "@components/charts/ScansBarChart/ScanBarChart";
import {
  ScansMeta,
  ScansType,
} from "@components/charts/ScansBarChart/ScanBarChart";

import { Scantoken } from "@type/scantokens";
import { Show } from "@type/shows";

import { getShowName } from "@lib/helpers";

// TODO: Add timerange component to filter by date

export const EditionScans = () => {
  const { eventId, editionId } = useParams();
  const { user } = useAuth();

  const [scanData, setScanData] = useState<{
    meta: ScansMeta;
    types: ScansType[];
  } | null>(null);
  // const [startDate, setStartDate] = useState<string>("2018-10-24 00:00:00");
  // const [endDate, setEndDate] = useState<string>("2024-09-23 23:59:59");
  const [loadingEditiondata, setLoadingEditionData] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showGrouped, setShowGrouped] = useState<boolean>(true);
  const [showCumulative, setShowCumulative] = useState<boolean>(false);
  const [scantokens, setScantokens] = useState<Scantoken[]>([]);
  const [shows, setShows] = useState<Show[]>([]);

  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [filterShow, setFilterShow] = useState<string>("");
  const [filterScantokens, setFilterScantokens] = useState<string[]>([]);

  const fetchEditionData = useCallback(async () => {
    try {
      setLoadingEditionData(true);
      const { scantokens } = await Api.get(`/editions/${editionId}/scantokens`);
      setScantokens(scantokens);
      const { shows } = await Api.get(`/editions/${editionId}/shows`);
      setShows(shows);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEditionData(false);
    }
  }, [editionId]);

  useEffect(() => {
    fetchEditionData();
  }, [fetchEditionData]);

  const fetchData = useCallback(async () => {
    try {
      // make date now in format yyyy-mm-dd hh:mm:ss
      const now = new Date().toISOString().split(".")[0].replace("T", " ");

      setLoading(true);
      const params = {
        start: "2017-01-01 00:00:00",
        end: now,
        scantokens: filterScantokens, //["831444659585", "054818045296"], // string[] of scantokens ids or empty if all tokens should be included
        show: filterShow !== "" ? filterShow : null, // string of show id or empty if all shows should be included
      };

      const data = await Api.get(`/editions/${editionId}/scanreport`, params);
      setScanData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId, filterScantokens, filterShow]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleTypeFilter = (uid: string) => {
    if (filterTypes.includes(uid)) {
      setFilterTypes(filterTypes.filter((type) => type !== uid));
    } else {
      setFilterTypes([...filterTypes, uid]);
    }
  };

  const getBarColor = (type: ScansType) => {
    if (type.scans > type.total) {
      return "error";
    }
    if (type.scans === type.total) {
      return "success";
    }
    return "info";
  };

  const refreshData = () => {
    fetchData();
  };

  const handleScantokensFilterChange = (str: string) => {
    if (str === "") {
      setFilterScantokens([]);
      return;
    }
    if (filterScantokens.includes(str)) {
      setFilterScantokens(filterScantokens.filter((token) => token !== str));
    } else {
      setFilterScantokens([...filterScantokens, str]);
    }
  };

  const getScansTotal = (
    types: ScansType[] | undefined,
    type: "ticket" | "voucher"
  ) => {
    if (!types) return 0;
    return types
      .filter((t) => t.type === type)
      .reduce((acc, t) => acc + t.scans, 0);
  };
  return (
    <>
      <UIPage>
        <PageHeader title="Scans" />
        <Container>
          {loadingEditiondata && <PageLoader />}
          {!loadingEditiondata && (
            <>
              <FilterBar>
                {/* <input
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            /> */}
                {shows.length > 1 && (
                  <FilterSelect
                    value={filterShow}
                    onChange={(showId) => setFilterShow(showId)}
                    label="Show"
                    options={shows.map((show) => ({
                      value: show.id,
                      title: getShowName(show, user!.language),
                    }))}
                  />
                )}
                {scantokens.length > 1 && (
                  <FilterSelect
                    multi
                    value={filterScantokens}
                    onChange={handleScantokensFilterChange}
                    label="Scantokens"
                    options={scantokens.map((scantoken) => ({
                      value: scantoken.id,
                      title: scantoken.name ?? scantoken.scantoken,
                    }))}
                  />
                )}

                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <ButtonGroup noMargin>
                    <Button
                      variant="outline"
                      onClick={() => setShowCumulative(!showCumulative)}
                      size="small"
                    >
                      {showCumulative ? "∑ Cumul" : "Δ Delta"}
                    </Button>
                    <Button
                      icon
                      variant="outline"
                      onClick={() => setShowGrouped(!showGrouped)}
                      size="small"
                    >
                      {showGrouped ? <RiExpandUpDownLine /> : <RiStackLine />}
                    </Button>
                    <Link
                      to={`/events/${eventId}/editions/${editionId}/settings/scankeys`}
                    >
                      <Button variant="outline" size="small">
                        <RiSettings3Line /> Scankeys
                      </Button>
                    </Link>
                    <Button
                      icon
                      variant="outline"
                      onClick={refreshData}
                      loading={loading}
                      size="small"
                    >
                      <RiRefreshLine />
                    </Button>
                  </ButtonGroup>
                </div>
              </FilterBar>

              {!loading &&
                (!scanData ||
                  !scanData?.types ||
                  scanData.types.length === 0) && (
                  <Empty
                    title="No scans found"
                    lead="We can not find data with this selection"
                  />
                )}

              {!loading && scanData && (
                <ScansBarChart
                  scanData={scanData}
                  cumulative={showCumulative}
                  grouped={showGrouped}
                  filterTypes={filterTypes}
                />
              )}

              {!loading && scanData && scanData.types.length > 0 && (
                <>
                  <TileGrid columns={2}>
                    <Tile
                      title="Tickets"
                      value={
                        <Count end={getScansTotal(scanData?.types, "ticket")} />
                      }
                    />
                    <Tile
                      title="Extras"
                      value={
                        <Count
                          end={getScansTotal(scanData?.types, "voucher")}
                        />
                      }
                    />
                  </TileGrid>
                  <Table scrollable>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell>Scans</TableHeadCell>
                        <TableHeadCell width="4rem"></TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scanData?.types.map((type, index) => (
                        <TableRow key={type.uid} color={type.branding_color}>
                          <TableCell>
                            <Text variant="h6" noMargin>
                              {type.name}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <ProgressBar
                              total={type.total}
                              value={type.scans}
                              color={getBarColor(type)}
                              index={index}
                            />
                          </TableCell>
                          <TableCell textAlign="right">
                            <Toggle
                              checked={filterTypes.includes(type.uid)}
                              onChange={() => toggleTypeFilter(type.uid)}
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
