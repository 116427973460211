import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { RiFileList3Line, RiTruckLine, RiQrCodeLine } from "@remixicon/react";

import { PageLoader } from "@components/PageLoader/PageLoader";

import { Api } from "@lib/api";

import { Reservation } from "@type/reservations";

const RESERVATIONS_ON_PAGE = 25;

type ReservationStatus =
  | "approved"
  | "collected"
  | "needs_approval"
  | "overdue"
  | "ready_for_pickup"
  | "returned"
  | "shipped";

// TODO : Add actions to the reservation
// TODO : Set Badgecolors for all statuses

export const Reservations = () => {
  const navigate = useNavigate();
  const { accountId } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [reservations, setReservations] = useState<Reservation[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(
    searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1
  );
  const [reservationsTotal, setReservationsTotal] = useState<
    number | undefined
  >(undefined);

  const fetchReservations = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        limit: RESERVATIONS_ON_PAGE,
        offset: (activePage - 1) * RESERVATIONS_ON_PAGE,
      };

      const { reservations, total } = await Api.get(
        `/accounts/${accountId}/reservations`,
        params
      );

      setReservations(reservations);
      setReservationsTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [activePage, accountId]);

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  useEffect(() => {
    setSearchParams({ ...searchParams, page: activePage.toString() });
  }, [activePage]);

  const getStatusColor = (status: ReservationStatus) => {
    switch (status) {
      case "overdue":
        return "error";
      case "returned":
        return "success";

      default:
        return "info";
    }
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Reservations" badge={reservationsTotal || undefined}>
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create a reservation</Button>
            </Link>
          </ButtonGroup>
        </PageHeader>
        <Container>
          {loading && <PageLoader />}
          {!loading && reservations && reservations.length === 0 && (
            <Empty
              title={"No reservations found"}
              lead={"No reservations found"}
              icon={<RiQrCodeLine />}
            />
          )}
          {!loading && reservations && reservations.length > 0 && (
            <>
              <Table scrollable clickable>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Reference</TableHeadCell>
                    <TableHeadCell>Event</TableHeadCell>
                    <TableHeadCell hideTablet>Date</TableHeadCell>
                    <TableHeadCell hideTablet>Status</TableHeadCell>
                    <TableHeadCell textAlign="right">Scanners</TableHeadCell>
                    <TableHeadCell width="10em" noWrap></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.map((reservation) => {
                    return (
                      <TableRow
                        key={reservation.id}
                        onClick={() => navigate(reservation.id.toString())}
                      >
                        <TableCell>
                          <Text variant="h6" noMargin>
                            <Link to="1">{reservation.reference}</Link>
                          </Text>
                        </TableCell>
                        <TableCell>{reservation.event.name}</TableCell>
                        <TableCell hideTablet>
                          {new Date(reservation.date).toLocaleDateString("nl", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </TableCell>
                        <TableCell hideTablet>
                          <Badge
                            content={reservation.status}
                            color={getStatusColor(reservation.status)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell textAlign="right">
                          {reservation.data.devices}
                        </TableCell>

                        <TableCell noWrap>
                          <ButtonGroup noMargin noWrap>
                            <Button
                              onClick={() => {
                                console.log("clicked tracking link");
                              }}
                              variant="outline"
                              color={
                                reservation.shipments[0]?.tracking_links.to
                                  ? "primary"
                                  : "subtle"
                              }
                              icon
                            >
                              <RiTruckLine />
                            </Button>
                            <Button
                              onClick={() => {
                                console.log("clicked tracking link");
                              }}
                              variant="outline"
                              color={
                                reservation.packing_slip ? "primary" : "subtle"
                              }
                              icon
                            >
                              <RiFileList3Line />
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                itemsPerPage={RESERVATIONS_ON_PAGE}
                itemsTotal={reservationsTotal ?? 0}
                translations={{
                  first_page: "First page",
                  last_page: "Last page",
                  active_page: "Page {{ activePage }} from {{ totalPages }}",
                }}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
