import { useEffect, useCallback, useState } from "react";
import {
  Badge,
  Container,
  PageHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  Tile,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Api } from "@lib/api";

import { PaymentMethodType } from "@type/paymentmethods";

import { PageLoader } from "@components/PageLoader/PageLoader";
import { TileGrid } from "@components/TileGrid/TileGrid";

interface Customer {
  //addresses: Address[];
  //companies: Company[];
  email: string;
  firstname: string;
  id: string;
  language: "nl" | "fr" | "en";
  lastname: string;
  orders: Order[];
  stats: {
    created_at: string;
    orders: number;
    revenue: number;
    spent: number;
  };
}

interface Order {
  id: string;
  created_at: string;
  reference: string;
  price: number;
  event: {
    id: string;
    name: string;
    // { ... }
  };
  edition: {
    id: string;
    name: string;
    // { ... }
  };
  testmode: boolean;
  payment: {
    id: string;
    identifier: PaymentMethodType;
    icons: string[];
    logo: string;
    name: string;
  };
  status: "OK" | "ERROR";
}

// TODO : Spacer at top of page
// TODO : Get PaymentMethodType from uikit of lib
// TODO : Update UIKit to version with subtitle on PageHeader

export const CustomerPage = () => {
  const { accountId, account } = useAuth();
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const { customer } = await Api.get(
        `/accounts/${accountId}/customers/${customerId}`
      );
      setCustomer(customer);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  if (!account) return null;

  return (
    <>
      <UIPage>
        {loading && <PageLoader />}

        {!loading && customer && (
          <>
            <PageHeader
              title={`${customer.firstname} ${customer.lastname}`.trim()}
              subtitle={
                <a href={`mailto:${customer.email}`}>{customer.email}</a>
              }
            />
            <Container>
              <TileGrid columns={3}>
                <Tile
                  title="Total spent"
                  value={customer.stats.spent.toLocaleString("nl", {
                    style: "currency",
                    currency: account.currency,
                  })}
                />
                <Tile title="Orders started" value={customer.orders.length} />
                <Tile title="Orders finished" value={customer.stats.orders} />
              </TileGrid>

              {customer.orders.length > 0 && (
                <Table scrollable>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>Event</TableHeadCell>
                      <TableHeadCell>Reference</TableHeadCell>
                      <TableHeadCell></TableHeadCell> {/* status icon */}
                      <TableHeadCell textAlign="right">Price</TableHeadCell>
                      <TableHeadCell></TableHeadCell> {/* payment icon */}
                      <TableHeadCell>Date</TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customer.orders.map((order) => (
                      <TableRow
                        key={order.id}
                        onClick={() => navigate(`/customers/${customer.id}`)}
                      >
                        <TableCell>
                          <Text variant="p" noMargin>
                            <Link
                              to={`/events/${order.event.id}/editions/${order.edition.id}/orders/${order.id}`}
                            >
                              {order.event.name}
                            </Link>
                          </Text>

                          <Text variant="small" noMargin>
                            {order.edition.name
                              ? order.edition.name
                              : "First edition"}
                          </Text>
                        </TableCell>
                        <TableCell>{order.reference}</TableCell>
                        <TableCell>
                          <Badge
                            content={order.status}
                            size="small"
                            color={order.status === "OK" ? "success" : "error"}
                          />
                        </TableCell>
                        <TableCell textAlign="right">
                          {order.price
                            ? order.price.toLocaleString("nl", {
                                style: "currency",
                                currency: account.currency,
                              })
                            : "Free"}
                        </TableCell>
                        <TableCell>
                          {order?.payment && (
                            // <PaymentIcon
                            //   method={order.payment.identifier}
                            //   size="small"
                            //   borderRadius
                            // />
                            <img
                              src={order.payment.logo}
                              alt={order.payment.name}
                              style={{
                                width: "2rem",
                                display: "block",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(order.created_at).toLocaleDateString("nl", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Container>
          </>
        )}
      </UIPage>
    </>
  );
};
